<template>
  <div>
    <div v-if="Boolean(file)" class="save-as-section">
      <div class="save-as-label">Guardar como:</div>
      <b-form-input type="text" v-model="save_as_input"></b-form-input>
    </div>
    <div class="mb-2">
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Seleccione o arrastre la imagen aquí..."
        drop-placeholder="Arrastre la imagen aquí..."
        accept="image/*"
      ></b-form-file>
    </div>
    <div v-if="url" class="image-div">
      <b-img :src="url" fluid alt="Imagen"></b-img>
      <!-- <b-img :src="url" fluid alt="Imagen"></b-img> -->
    </div>
    <div class="buttons-div">
      <b-button v-if="url" @click="save">Guardar</b-button>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { toast } from "@/utils/utils";

export default {
  name: "ImageSelector",
  data() {
    return {
      file: null,
      url: null,
      data: null,
      save_as_input: "",
    };
  },
  methods: {
    save() {
      const formData = new FormData();
      formData.append("image", this.file);
      formData.append("name", this.save_as_input);
      this.$store.dispatch(names.POST_IMAGE, formData).then((response) => {
        this.$emit("created", response);
        toast("Imagen guardada.");
      });
    },
  },
  watch: {
    file(newFile) {
      this.url = URL.createObjectURL(this.file);
      this.save_as_input = this.file.name;
      if (newFile && !newFile.type.startsWith("image/")) {
        this.$nextTick(() => {
          this.file = null;
        });
      }
    },
  },
};
</script>

<style scoped>
.image-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  justify-items: center;
  width: 100%;
}
img {
  max-width: 150px;
  max-height: 150px;
}
.buttons-div {
  text-align: right;
}
.save-as-section {
  display: flex;
  margin-bottom: 0.55em;
}
.save-as-label {
  width: 35%;
  margin-top: 0.8em;
}
</style>